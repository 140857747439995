import { BasicSelect, ModelSelect } from 'vue-search-select';
import store from '../../../../../../store';
import commonHelper from '@/app/utility/common.helper.utility';
import AddgstCredentials from './addgstCredentials';
import { required } from 'vuelidate/lib/validators';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'gstCredentials',
  watch: {
    currentPage: function() {
      this.getgstCredentialsGridViewList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getgstCredentialsGridViewList();
    }
  },
  components: {
    BasicSelect,
    ModelSelect,
    AddgstCredentials
  },
  props: {},
  data() {
    return {
      showChildModal: false,
      showGstCredentialsModal: false,
      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showValueSetModal: false,
      showAddGstCredentialsModal: false,
      gstCredentialsRowDetail: null,
      vsetCode: null,
      selectedGSTNType: {
        value: null,
        text: null
      },
      legalEntityList: [
        {
          value: null,
          text: null
        }
      ],
      GSTNTypeList: [
        {
          value: null,
          text: null
        }
      ],
      selectedLegalEntity: {
        value: null,
        text: null
      },
      gstCredentialsdata: [],
      fields: [
        {
          key: 'le_id',
          class: 'd-none'
        },
        {
          key: 'gst_credential_id',
          class: 'd-none'
        },
        {
          key: 'gstn_type',
          label: 'GSTN Type'
        },
        {
          key: 'gst_number',
          label: 'GST Number'
        },
        {
          key: 'username',
          label: 'Username'
        },
      ],
    };
  },
  validations: {
    selectedLegalEntity: {
      text: {
        required
      }
    },
    selectedGSTNType: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.getgstCredentialsValueSet();
    this.getLegalEntity();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddGstCredentialsModal = true;
          this.gstCredentialsRowDetail = null;
        }
        if (actionName === 'download' && !this.showAddGstCredentialsModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'gstCredentials/getGstCredentialsList',
            'gst-credentials',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getgstCredentialsGridViewList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.selectedLegalEntity.value,
          gstn_type: this.selectedGSTNType.value
        };
        this.loader = true;
        this.$store
          .dispatch('gstCredentials/getGstCredentialsList', this.payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.gstCredentialsdata = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getLegalEntity() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.user_id = store.state.auth.userId;
      payload.dependentVariable.res_id = store.state.shared.responsibilityId;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.value_code
              };
            });
            this.legalEntityList = leagalEntity;
            this.selectedLegalEntity =
              leagalEntity && leagalEntity.length === 1
                ? leagalEntity[0]
                : this.selectedLegalEntity;
          }
        });
    },
    clear() {
      this.selectedGSTNType = {
        value: null,
        text: null
      };
        this.selectedLegalEntity = {
          value: null,
          text: null
        };
        this.gstCredentialsdata = [];
        this.totalRows = null;
      this.currentPage = 1;
    },
    rowSelected(item) {
      this.gstCredentialsRowDetail = item;
      this.showHideGstCredentialsModal(true);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
       else if (this.vsetCode === 'GSTN_TYPE') {
        this.selectedGSTNType = {
          value: item.value_meaning,
          text: item.value_code
        };
      }
    },
    getgstCredentialsValueSet() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'GSTN_TYPE')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.GSTNTypeList = getValueAndText;
          }
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideGstCredentialsModal(flag) {
      this.showAddGstCredentialsModal = flag;
    },
    clearVsetValues(vsetCode){
      if (vsetCode === this.selectedLegalEntity.value) {
        this.selectedLegalEntity = {
          value: null,
          text: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};