import { ModelSelect } from 'vue-search-select';
import store from '../../../../../../../store';
import appStrings from '@/app/utility/string.utility';
export default {
    name: 'addgstCredentials',
    components: {
        ModelSelect
    },
    props: ['gstCredentialsRowDetail'],
    data() {
        return {
            loader: false, 
            unsubscribe: null,
            isSuccess: false,
            showAlert: false,
            responseMsg: '',
            parent_value_set_id: null,
            vsetCode: null,
            gstNumber: null,
            userName: null,
            editMode: false,
            showValueSetModal: false,
            gstCredentialsId: 0,
            legalEntityList: [],
            GSTNTypeList: [
              {
                value: null,
                text: null
              }
            ],
            selectedLegalEntity: {
                value: null,
                text: null
              },
            selectedGSTNType: {
                value: null,
                text: null
            },
        }
    },
    mounted() {
      this.getgstCredentialsValueSet();
      if (this.gstCredentialsRowDetail) {
        this.fillRecordFromLeaseTowerParent(this.gstCredentialsRowDetail);
      } else {
        this.editMode = true;
      }
        this.getLegalEntity();
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
              const actionName = state.shared.actionName;
              if (actionName === 'save' && this.editMode) {
                this.addEditgstCredentials();
              }
              if (actionName === 'update') {
                this.editMode = true;
              }
            }
          });
    },
    methods: {
      fillRecordFromLeaseTowerParent(item) {
        this.selectedLegalEntity.value = item.le_id;
        this.selectedLegalEntity.text = item.le_id;
        this.gstCredentialsId = item.gst_credential_id;
        this.selectedGSTNType = {
          value: item.gstn_type,
          text: item.gstn_type
        },
        this.gstNumber = item.gst_number;
        this.userName = item.username;
      },
      addEditgstCredentials() {
        const payload ={
          le_id: this.selectedLegalEntity.value,
          gst_credential_id: this.gstCredentialsId, 
          gstn_type: this.selectedGSTNType.value,
          gst_number: this.gstNumber,
          username: this.userName,
        };
      this.loader = true;
      this.$store
        .dispatch('gstCredentials/addEditgstCredentials', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.showAlert = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
        getLegalEntity() {
            const payload = store.state.shared.responsibilityId;
            this.$store.dispatch('receipt/getOrganizationLov', payload).then(response => {
              if (response.status === 200) {
                const results = response.data.data;
                const leagalEntity = results.map(type => {
                  return {
                    value: type.org_id,
                    text: type.org_name
                  };
                });
                this.legalEntityList = leagalEntity;
                this.selectedLegalEntity =
                  leagalEntity && leagalEntity.length===1
                    ? leagalEntity[0]
                    : this.selectedLegalEntity;
              }
            });
          },  
        selectedvalueSet(item) {
            if (this.vsetCode === 'GSTN_TYPE') {
                this.selectedGSTNType = {
                  value: item.value_meaning,
                  text: item.value_code
                };
              }
        },
        getgstCredentialsValueSet() {
          this.$store
            .dispatch('receipt/getLOVBySetType', 'GSTN_TYPE')
            .then(response => {
              if (response.status === 200) {
                const results = response.data.data;
                const getValueAndText = results.map(type => {
                  return {
                    value: type.value_code,
                    text: type.value_meaning
                  };
                });
                this.GSTNTypeList = getValueAndText;
              }
            });
        },
        openValueSetModal(vsetCode) {
            this.vsetCode = vsetCode;
            this.showValueSetModal = true;
            this.setTimeVsetCode = setTimeout(() => {
              const vSetData = {
                valueSetName: vsetCode,
                multiFlag: null
              };
              this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
          },
          closeValueSetModal() {
            this.showValueSetModal = false;
          },
        showHideGstCredentialsModal(flag) {
            this.showAddGstCredentialsModal = flag;
          }
    },
    beforeDestroy() {
        this.unsubscribe();
    }
};